// import * as React from 'react';
// import { ColumnDirective, ColumnsDirective, GridComponent, Inject, Page, Sort } from '@syncfusion/ej2-react-grids';
// import { registerLicense } from '@syncfusion/ej2-base';

// /* Add Syncfusion license key or use environment variable below */
// registerLicense(process.env.REACT_APP_SYNCFUSION_LICENSE_KEY);

// export default function App() {

//   const data = [
//     { OrderID: 10248, CustomerID: 'VINET', ShipCountry: 'France' },
//     { OrderID: 10249, CustomerID: 'TOMSP', ShipCountry: 'Germany' },
//     { OrderID: 10250, CustomerID: 'HANAR', ShipCountry: 'Brazil' },
//     { OrderID: 10251, CustomerID: 'VICTE', ShipCountry: 'Venezuela' },
//     { OrderID: 10252, CustomerID: 'SUPRD', ShipCountry: 'Belgium' },
//     { OrderID: 10253, CustomerID: 'CHOPS', ShipCountry: 'Switzerland' }
//   ];

//   return (<React.Fragment>
//     <h2>Syncfusion React Grid Component</h2>
//     <GridComponent dataSource={data} allowPaging={true} pageSettings={{ pageSize: 5 }}>
//       <ColumnsDirective>
//         <ColumnDirective field='OrderID' width='100'/>
//         <ColumnDirective field='CustomerID' width='100'/>
//         <ColumnDirective field='ShipCountry' width='100'/>
//       </ColumnsDirective>
//       <Inject services={[Page, Sort]}/>
//     </GridComponent>
//   </React.Fragment>);
// };

import React, { useState, useEffect } from 'react';
import { ColumnDirective, ColumnsDirective, GridComponent, Inject, Page, Sort } from '@syncfusion/ej2-react-grids';
import { registerLicense } from '@syncfusion/ej2-base';
import axios from 'axios'; // Make sure to install axios by running `npm install axios`

/* Add Syncfusion license key or use environment variable below */
registerLicense("Ngo9BigBOggjHTQxAR8/V1NCaF5cWWFCdkx3WmFZfVpgc19DZFZRRWYuP1ZhSXxXdkJhWn9dcXBWRGJaVkE=");

export default function App() {
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://98a50agpbl.execute-api.eu-west-1.amazonaws.com/prod/events');
        const apiData = response.data.body;
        // Here we parse the JSON string since the API response seems to be a stringified JSON
        const parsedData = JSON.parse(apiData);
        console.log("here")
        // Transform the data structure here if necessary to match the GridComponent fields
        setData(parsedData.map(item => ({
          // Assuming you want to display these fields - you might need to adjust
          OrderID: item.id,
          AFSDKVersion: item['af-sdk-version'],
          AFPluginVersion: item['af-plugin-version'],
          GcdSuccess: item['gcd-success'] === "true" ? '✅' : '🚫',
          SkadRulesSuccess: item.id.includes("android") ? 'N/A' : (item['skad-rules-success'] === "true" ? '✅' : '🚫'),
          DeepLinkColdStartSuccess: item['deeplink-coldstart-success'] === "true" ? '✅' : '🚫'
        })));
      } catch (error) {
        console.error('There was an error fetching the API data', error);
      }
    };

    fetchData();
  }, []);

  return (
    <React.Fragment>
      <br />
      <br />
      <br />
      <h2>Plugins Automation Report</h2>
      <GridComponent dataSource={data} allowPaging={true} pageSettings={{ pageSize: 5 }}>
        <ColumnsDirective>
          <ColumnDirective field='OrderID' width='100' />
          <ColumnDirective field='GcdSuccess' headerText='GCD Success ' width='100' textAlign='Center' />
          <ColumnDirective field='SkadRulesSuccess' headerText='SKAd Rules Success' width='100' textAlign='Center' />
          <ColumnDirective field='DeepLinkColdStartSuccess' headerText='DeepLink Cold Start Success' width='100' textAlign='Center' />
          <ColumnDirective field='AFSDKVersion' headerText='Appsflyer SDK Version' width='100' textAlign='Center' />
          <ColumnDirective field='AFPluginVersion' headerText='Plugin Version' width='100' textAlign='Center' />
        </ColumnsDirective>
        <Inject services={[Page, Sort]} />
      </GridComponent>
    </React.Fragment>
  );
};